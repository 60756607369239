html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

* {
  box-sizing: border-box;
}
